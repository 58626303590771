(function ($) {
  'use strict';

  $(document).ready(function () {
    var handlesDateInputs = window.Modernizr.inputtypes.date;
    var uaParserAvailable = window.scala.utils.checkPeriodically(function () {
      return window.UAParser;
    });

    uaParserAvailable.then(function () {
      var uaParser = new window.UAParser();
      var isDesktopBrowser = typeof uaParser.getDevice().type === 'undefined';

      // console.log('isDesktopBrowser', isDesktopBrowser);
      var datePickerAvailable = window.scala.utils.checkPeriodically(function () {
        return $.datepicker;
      });

      if (isDesktopBrowser || !handlesDateInputs) {
        return datePickerAvailable;
      }

      return window.Promise.reject();
    })
    .then(function () {
      // var lang = $('html').attr('lang').replace(/-.*/, '');
      var lang = $('html').attr('lang').replace('fr-FR', 'fr');

      $.datepicker.setDefaults($.datepicker.regional[lang]);

      /*
      // $('input[type="date"]').attr('type', 'text').datepicker();
      $('input[type="date"]')
      .attr('size', '10')
      .attr('type', 'text')
      .each(function (index, item) {
        var input = $(item);
        var min = input.attr('min');
        var max = input.attr('max');

        input.datepicker({
          dateFormat: 'yy-mm-dd', // same as input type date
          minDate: new Date(min),
          maxDate: new Date(max)
        });
      });*/

      // remplacement date pr meilleure gestion : todo

      $('input[type="date"]').each(function (index, item) {
        var input = $(item);

        var id = input.attr('id');
        var idFront = input.attr('id') + '-front-calendar-jquery';
        var value = input.attr('value');
        //var inputName = input.attr('name');
        var min = input.attr('min');
        var max = input.attr('max');
        var noMagentoClass = input.attr('class').replace('datetime-picker', '');
        var onlyMagentoClass = 'datetime-picker';

        // console.log($.datepicker.formatDate(value, ''));

        var cloneInput = input.clone()
        .attr('id', idFront)
        .attr('type', 'text')
        .attr('data-jquery-datepicker', '')
        .removeAttr('value')
        .attr('class', noMagentoClass)
        .removeAttr('name')
        .insertAfter(input);

        input.attr('type', 'hidden')
        .attr('class', onlyMagentoClass);

        /* input.attr('id', idFront)
        .attr('size', '10')
        .attr('type', 'text')
        .attr('data-jquery-datepicker', '')
        .removeAttr('value')
        .attr('class', noMagentoClass)
        .removeAttr('name');

        $('<input/>')
        .insertAfter(input)
        .attr('value', value)
        .attr('name', inputName)
        .attr('id', id)
        .attr('type', 'hidden')
        .attr('class', onlyMagentoClass); */

        cloneInput.datepicker({
          altFormat: 'yy-mm-dd',
          altField: '#' + id,
          minDate: new Date(min),
          maxDate: new Date(max),
          changeYear: true,
          yearRange: "c-100:c+10",
          onSelect: function () { // specifier explicitement ce callback corrige un souci sur IE... va comprendre...

            /*
              Il est nécessaire de déclencher manuellement l'évenement
              car lorsque jquery update automatiquement
              le champ caché cet évenement n'est pas dispatché
            */
            input.trigger('change');
          }
        });

        if (value) {
          cloneInput.datepicker('setDate', new Date(value));
        }

        cloneInput.on('change', function (event) {
          cloneInput.datepicker('setDate', cloneInput[0].value);
          cloneInput.blur();
        });

        $('label[for="' + id + '"]').attr('for', idFront);
      });
    })
    .catch(function (reason) {
      // nothing yet
      if (reason) {
        console.error(reason);
      }
    });
  });
}(window.jQuery.noConflict()));

(function ($) {
  'use strict';

  var baseSelectors = ['input[type="date"]', 'input[data-jquery-datepicker]'];

  var specifyWithAttr = function (attribute) {
    return function (selector) {
      return selector + '[' + attribute + ']';
    };
  };

  var mapAndJoinSelectors = function (selectors) {
    return function (toMap) {
      return selectors.map(toMap).join(',');
    };
  };

  var mapAndJoinBaseSelectors = mapAndJoinSelectors(baseSelectors);

  var setConditioningDateInput = function (attr, limiter, jqueryOption) {
    var fullSelector = mapAndJoinBaseSelectors(specifyWithAttr(attr));

    $(fullSelector).each(function (index, conditionedInput) {
      var $conditionedInput = $(conditionedInput);
      var conditionInputSelector = '[name="' + $conditionedInput.attr(attr) + '"]';
      var $conditionInput = $(conditionInputSelector);
      var getConditionInput = function () {
        return $conditionedInput
        .closest('form')
        .find(conditionInputSelector)[0];
      };

      var setCondition = function () {
        var value = getConditionInput().value;

        $conditionedInput.attr(limiter, value);

        if ($conditionedInput.hasClass('hasDatepicker')) {
          $conditionedInput.datepicker('option', jqueryOption, new Date(value));
        }
      };

      $conditionInput.on('change', setCondition);
      setCondition();
    });
  };

  setConditioningDateInput('data-min-input', 'min', 'minDate');
  setConditioningDateInput('data-max-input', 'max', 'maxDate');
  // var selectorMin = mapAndJoinSelectors(specifyWithAttr(minInputAttribute));
  // var selectorMax = mapAndJoinSelectors(specifyWithAttr(maxInputAttribute));

  // $(selectorMin).
}(window.jQuery.noConflict()));
